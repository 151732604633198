<template>
  <div :class="$style.order">
    <Icon name="sommelier" />
    <Input
      :class="$style.inputOrder"
      placeholder="Введите текст"
      :value="value.delivery"
      @input="(val) => $emit('input', { ...value, text: val })"
      maxlength="36"
    />
    <Button :class="$style.button" type="primary">Спросить сомелье</Button>
    <Input
      :class="$style.inputOrder"
      placeholder="Введите сообщение в WhatsApp"
      :value="value.buyLink"
      @input="(val) => $emit('input', { ...value, message: val })"
    />
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
import Button from '@/components/atoms/Button'
import Input from '@/components/atoms/Input'

export default {
  components: {
    Button,
    Input,
    Icon,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';
.order {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 20rem;
  min-height: 21rem;
  padding: 2rem 0;
  background: $bright-gold;
  .inputOrder {
    background: transparent;
    color: $brown;
    text-align: center;
  }
  .inputOrder::placeholder {
    color: $dark-orange;
  }
  span {
    color: $brown;
    text-align: center;
    margin: 1rem 0 2rem;
  }
  .button {
    background: $dark-orange;
    width: 70%;
    height: 4.5rem;
  }
}
</style>
